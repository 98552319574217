import cx from 'classnames'
/* eslint-disable import/no-extraneous-dependencies */
import Price from 'components/src/presentation/Price'
import { Props, renderPrice } from 'components/src/presentation/PriceBox'
import styles from 'components/src/presentation/PriceBox/PriceBox.module.scss'
/* eslint-enable import/no-extraneous-dependencies */

const DIFF_VALUE = 0.05 // difference between advice price and special price (if the diff between is less or even it won't show adviceprice)
export const renderoutletAdvicePrice = (
    price: number | undefined,
    type: string,
    variantBig?: boolean,
    priceClassName?: string,
    advice?: boolean,
) => {
    if (!price) {
        return null
    }

    return (
        <Price
            price={price}
            special={type === 'special'}
            advice={advice}
            variantBig={variantBig}
            className={priceClassName}
        />
    )
}

function checkIfAdvicePriceIsDifferent(
    advicePrice: number,
    specialPrice: number,
) {
    const difference = Math.round((advicePrice - specialPrice) * 100) / 100
    if (difference <= DIFF_VALUE) {
        return false
    }
    return true
}

const PriceBox = ({
    regularPrice,
    oldPrice,
    specialPrice,
    className,
    priceClassName,
    variantBig,
    outletAdviceprice,
}: Props) => (
    <div className={cx(className, styles.default)}>
        {renderPrice(regularPrice, 'regular', variantBig, priceClassName)}
        {renderPrice(oldPrice, 'old', variantBig, priceClassName)}
        {outletAdviceprice &&
            specialPrice &&
            checkIfAdvicePriceIsDifferent(
                outletAdviceprice,
                specialPrice?.value,
            ) &&
            renderoutletAdvicePrice(
                outletAdviceprice,
                'advice',
                variantBig,
                priceClassName,
                true,
            )}
        {renderPrice(specialPrice, 'special', variantBig, priceClassName)}
    </div>
)

export default PriceBox
